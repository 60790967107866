// ======Ambient Timepicker======

if (_ambient === undefined) var _ambient = {};
if (_ambient.timepicker === undefined) _ambient.timepicker = {};
if (_ambient.timepicker.functions === undefined) _ambient.timepicker.functions = {};
if (_ambient.timepicker.vars === undefined) _ambient.timepicker.vars = {};
if (_ambient.timepicker.vars.hours === undefined) _ambient.timepicker.vars.hours = {};
if (_ambient.timepicker.vars.hours.world === undefined) _ambient.timepicker.vars.hours.world = [];
if (_ambient.timepicker.vars.hours.us === undefined) _ambient.timepicker.vars.hours.us = [];
if (_ambient.timepicker.vars.minutes === undefined) _ambient.timepicker.vars.minutes = [];
if (_ambient.timepicker.vars.seconds === undefined) _ambient.timepicker.vars.seconds = [];

for (var i = 0; i < 24; i++) {
    var hour = String(i);

    if (i < 10) {
        hour = '0' + hour;
    }

    _ambient.timepicker.vars.hours.world.push(hour);

    if (i > 0 && i < 13) {
        _ambient.timepicker.vars.hours.us.push(hour);
    }
}

for (var i = 0; i < 60; i++) {
    var minSec = String(i);

    if (i < 10) {
        minSec = '0' + minSec;
    }

    _ambient.timepicker.vars.minutes.push(minSec);
    _ambient.timepicker.vars.seconds.push(minSec);
}

_ambient.timepicker.functions.createObj = function (timePicker) {
    var rtnObj = {
        pickerInput: timePicker.cloneNode(true),
        AMPM: false,
        startValue: undefined,
        startHour: undefined,
        startMinute: undefined,
        startSecond: undefined,
        timePickerContainer: document.createElement('div'),
        timePickerSelectContainer: document.createElement('div'),
        timePickerSelectHour: document.createElement('select'),
        timePickerSelectMinute: document.createElement('select'),
        timePickerSelectSecond: document.createElement('select'),
        timePickerSelectMeridies: undefined,
        separator: document.createElement('span'),
        separatorEmpty: document.createElement('span'),
    };

    rtnObj.startValue = rtnObj.pickerInput.value.split(':');
    if (rtnObj.startValue && rtnObj.startValue.length === 3) {
        rtnObj.startHour = parseInt(rtnObj.startValue[0]);
        rtnObj.startMinute = parseInt(rtnObj.startValue[1]);
        rtnObj.startSecond = parseInt(rtnObj.startValue[2]);
    }

    rtnObj.timePickerSelectContainer.setAttribute('ambient-time-picker-select-container', '');
    rtnObj.timePickerContainer.setAttribute('ambient-time-picker-container', '');

    rtnObj.separatorEmpty.classList.add('separator');
    rtnObj.separator.classList.add('separator');
    rtnObj.separator.appendChild(document.createTextNode(':'));


    rtnObj.timePickerSelectHour.setAttribute('ambient-time-picker-hour', '');
    rtnObj.timePickerSelectMinute.setAttribute('ambient-time-picker-minute', '');
    rtnObj.timePickerSelectSecond.setAttribute('ambient-time-picker-second', '');

    return rtnObj;
}

_ambient.timepicker.functions.setMeridies = function (timePickerObj) {
    var AMPMAttribute = timePickerObj.pickerInput.getAttribute('ambient-time-picker-am-pm');

    if (AMPMAttribute && AMPMAttribute.toLowerCase() === 'true') {
        timePickerObj.AMPM = true;
        timePickerObj.timePickerSelectMeridies = document.createElement('select');
        timePickerObj.timePickerSelectMeridies.setAttribute('ambient-time-picker-meridies', '')
        var pm = document.createElement('option');
        var am = document.createElement('option');
        am.setAttribute('value', '0');
        pm.setAttribute('value', '1');

        if (timePickerObj.startHour && timePickerObj.startHour >= 12) {
            pm.setAttribute('selected', 'selected');
        } else {
            am.setAttribute('selected', 'selected');
        }

        am.appendChild(document.createTextNode('AM'));
        pm.appendChild(document.createTextNode('PM'));
        timePickerObj.timePickerSelectMeridies.appendChild(am);
        timePickerObj.timePickerSelectMeridies.appendChild(pm);
    }
}

_ambient.timepicker.functions.setHours = function (timePickerObj) {
    var hoursList = (function () {
        if (timePickerObj.AMPM) {
            return _ambient.timepicker.vars.hours.us;
        } else {
            return _ambient.timepicker.vars.hours.world;
        }
    }())

    var value = timePickerObj.startHour;

    if (timePickerObj.AMPM && timePickerObj.startHour && timePickerObj.startHour > 12) {
        value = timePickerObj.startHour - 12;
    }

    Object.keys(hoursList).forEach(function (idx) {
        var option = document.createElement('option');
        option.setAttribute('value', hoursList[idx]);
        option.appendChild(document.createTextNode(hoursList[idx]));

        if (!value && timePickerObj.AMPM && hoursList[idx] === '12') {
            option.setAttribute('selected', 'selected');
        }

        if (value && parseInt(hoursList[idx]) && parseInt(hoursList[idx]) === value) {
            option.setAttribute('selected', 'selected');
        }

        timePickerObj.timePickerSelectHour.appendChild(option);
    });
}

_ambient.timepicker.functions.setMinutes = function (timePickerObj) {
    Object.keys(_ambient.timepicker.vars.minutes).forEach(function (idx) {
        var option = document.createElement('option');
        option.setAttribute('value', _ambient.timepicker.vars.minutes[idx]);
        option.appendChild(document.createTextNode(_ambient.timepicker.vars.minutes[idx]));

        if (timePickerObj.startMinute && parseInt(_ambient.timepicker.vars.minutes[idx]) && parseInt(_ambient.timepicker.vars.minutes[idx]) === timePickerObj.startMinute) {
            option.setAttribute('selected', 'selected');
        }

        timePickerObj.timePickerSelectMinute.appendChild(option);
    });
}

_ambient.timepicker.functions.setSeconds = function (timePickerObj) {
    Object.keys(_ambient.timepicker.vars.seconds).forEach(function (idx) {
        var option = document.createElement('option');
        option.setAttribute('value', _ambient.timepicker.vars.seconds[idx]);
        option.appendChild(document.createTextNode(_ambient.timepicker.vars.seconds[idx]));

        if (timePickerObj.startSecond && parseInt(_ambient.timepicker.vars.minutes[idx]) && parseInt(_ambient.timepicker.vars.minutes[idx]) === timePickerObj.startSecond) {
            option.setAttribute('selected', 'selected');
        }

        timePickerObj.timePickerSelectSecond.appendChild(option);
    });
}

_ambient.timepicker.functions.updateValue = function (timePickerObj) {
    var hour = timePickerObj.timePickerSelectHour.value;
    var minute = timePickerObj.timePickerSelectMinute.value;
    var second = timePickerObj.timePickerSelectSecond.value;

    if (timePickerObj.AMPM) {
        var meridies = timePickerObj.timePickerSelectMeridies.value;

        if (meridies && meridies === '1' && hour !== '00' && hour !== '12') {
            hour = String(parseInt(hour) + 12);
        }
        if (meridies && meridies === '0' && hour === '12') {
            hour = '00';
        }
    }

    timePickerObj.pickerInput.value = hour + ':' + minute + ':' + second;
}

_ambient.timepicker.functions.addListeners = function (timePickerObj) {
    timePickerObj.timePickerSelectHour.addEventListener('change', function () {
        _ambient.timepicker.functions.updateValue(timePickerObj);
    });
    timePickerObj.timePickerSelectMinute.addEventListener('change', function () {
        _ambient.timepicker.functions.updateValue(timePickerObj);
    });
    timePickerObj.timePickerSelectSecond.addEventListener('change', function () {
        _ambient.timepicker.functions.updateValue(timePickerObj);
    });

    if (timePickerObj.timePickerSelectMeridies) {
        timePickerObj.timePickerSelectMeridies.addEventListener('change', function () {
            _ambient.timepicker.functions.updateValue(timePickerObj);
        });
    }
}

_ambient.timepicker.functions.initialize = function () {
    var timePickers = document.querySelectorAll('input[ambient-time-picker]');
    Object.keys(timePickers).forEach(function (idx) {
        var parentNode = timePickers[idx].parentNode;
        if (parentNode && !parentNode.hasAttribute('ambient-time-picker-container')) {
            var timePickerObj = _ambient.timepicker.functions.createObj(timePickers[idx].cloneNode(true));

            _ambient.timepicker.functions.setMeridies(timePickerObj);
            _ambient.timepicker.functions.setHours(timePickerObj);
            _ambient.timepicker.functions.setMinutes(timePickerObj);
            _ambient.timepicker.functions.setSeconds(timePickerObj);

            timePickerObj.timePickerContainer.appendChild(timePickerObj.pickerInput);
            timePickerObj.timePickerContainer.appendChild(timePickerObj.timePickerSelectContainer);

            timePickerObj.timePickerSelectContainer.appendChild(timePickerObj.timePickerSelectHour);
            timePickerObj.timePickerSelectContainer.appendChild(timePickerObj.separator.cloneNode(true));
            timePickerObj.timePickerSelectContainer.appendChild(timePickerObj.timePickerSelectMinute);
            timePickerObj.timePickerSelectContainer.appendChild(timePickerObj.separator.cloneNode(true));
            timePickerObj.timePickerSelectContainer.appendChild(timePickerObj.timePickerSelectSecond);

            if (timePickerObj.timePickerSelectMeridies) {
                timePickerObj.timePickerSelectContainer.appendChild(timePickerObj.separatorEmpty.cloneNode(true));
                timePickerObj.timePickerSelectContainer.appendChild(timePickerObj.timePickerSelectMeridies);
            }

            timePickerObj.pickerInput.setAttribute('hidden', 'hidden');
            timePickers[idx].parentNode.replaceChild(timePickerObj.timePickerContainer, timePickers[idx]);

            _ambient.timepicker.functions.updateValue(timePickerObj);
            _ambient.timepicker.functions.addListeners(timePickerObj);
        }
    });
};

_ambient.timepicker.functions.timePickerInit = function () {
    if (_ambient && _ambient.htmlObserver) {
        _ambient.htmlObserver.addFunction(_ambient.timepicker.functions.initialize, true);
    } else {
        _ambient.timepicker.functions.initialize();
    }
}

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    _ambient.timepicker.functions.timePickerInit();
} else {
    document.addEventListener("DOMContentLoaded", _ambient.timepicker.functions.timePickerInit);
}
