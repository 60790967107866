// ======Scroll To======

if (_ambient.scrollTo == undefined) _ambient.scrollTo = {}
if (_ambient.scrollTo.functions == undefined) _ambient.scrollTo.functions = {};
if (_ambient.scrollTo.elements == undefined) _ambient.scrollTo.elements = {};
_ambient.scrollTo.elements.scrollTo = document.querySelectorAll('[ambient-scroll-to]');
_ambient.scrollTo.elements.scrollToTop = document.querySelectorAll('[ambient-scroll-to-top-button]');
_ambient.scrollTo.offsetTop;

// Scroll To - scroll execution function and helpers
_ambient.scrollTo.functions.utils = {
    /**
     * Helpers.
     */
    documentVerticalScrollPosition: function () {
        if (self.pageYOffset) return self.pageYOffset; // Firefox, Chrome, Opera, Safari.
        if (document.documentElement && document.documentElement.scrollTop) return document.documentElement.scrollTop; // Internet Explorer 6 (standards mode).
        if (document.body.scrollTop) return document.body.scrollTop; // Internet Explorer 6, 7 and 8.
        return 0; // None of the above.
    },

    currentPageScrollTop: function () { return document.documentElement.scrollTop || document.body.scrollTop; },

    viewportHeight: function () { return (document.compatMode === "CSS1Compat") ? document.documentElement.clientHeight : document.body.clientHeight; },

    documentHeight: function () { return (document.height !== undefined) ? document.height : document.body.offsetHeight; },

    documentMaximumScrollPosition: function () { return this.documentHeight() - this.viewportHeight(); },

    elementVerticalClientPositionById: function (id) {
        var element = document.getElementById(id);
        var rectangle = element.getBoundingClientRect();
        return rectangle.top;
    },

    /**
     * Animation tick.
     */
    scrollVerticalTickToPosition: function (currentPosition, targetPosition, clickedElem) {
        var filter = 0.2;
        var fps = 60;
        var difference = parseFloat(targetPosition) - parseFloat(currentPosition);

        // Snap, then stop if arrived.
        var arrived = (Math.abs(difference) <= 0.5);
        if (arrived) {
            // Apply target.
            scrollTo(0.0, targetPosition);

            if (clickedElem) {
                clickedElem.dispatchEvent(new CustomEvent('AmbientScrollToEnded', { bubbles: true, detail: { elem: clickedElem } }));
            }

            return;
        }

        // Filtered position.
        currentPosition = (parseFloat(currentPosition) * (1.0 - filter)) + (parseFloat(targetPosition) * filter);

        // Apply target.
        scrollTo(0.0, Math.round(currentPosition));

        // Schedule next tick.
        setTimeout(function () {
            _ambient.scrollTo.functions.utils.scrollVerticalTickToPosition(currentPosition, targetPosition, clickedElem);
        }, (1000 / fps));
        // setTimeout("_ambient.scrollTo.functions.utils.scrollVerticalTickToPosition(" + currentPosition + ", " + targetPosition + ")", (1000 / fps));
    },

    /**
     * For public use.
     *
     * @param id The id of the element to scroll to.
     * @param padding Top padding to apply above element.
     */
    scrollVerticalToElementById: function (id, padding, clickedElem) {
        var element = document.getElementById(id);
        if (element == null) {
            console.warn('Cannot find element with id \'' + id + '\'.');
            return;
        }

        var targetPosition = this.documentVerticalScrollPosition() + this.elementVerticalClientPositionById(id) - padding;
        var currentPosition = this.documentVerticalScrollPosition();

        // Clamp.
        var maximumScrollPosition = this.documentMaximumScrollPosition();
        if (targetPosition > maximumScrollPosition) targetPosition = maximumScrollPosition;

        // Start animation.
        this.scrollVerticalTickToPosition(currentPosition, targetPosition, clickedElem);
    }
}

// Scroll To - page top offset function
_ambient.scrollTo.functions.setTopOffset = function () {
    _ambient.scrollTo.offsetTop = _ambient.scrollTo.functions.utils.currentPageScrollTop();
}

// Scroll To - scroll to top of page
_ambient.scrollTo.functions.scrollToTop = function (e) {
    e.preventDefault();
    var body = document.body;

    var bodyId = body.getAttribute('id');

    if (!bodyId) {
        body.setAttribute('id', 'document-body');
        bodyId = body.getAttribute('id');
    }

    e.target.dispatchEvent(new CustomEvent('AmbientScrollToStarted', { bubbles: true, detail: { elem: e.target, toId: bodyId } }));

    _ambient.scrollTo.functions.utils.scrollVerticalToElementById(bodyId, 0, e.target);
}

// Scroll To - scroll to element id
_ambient.scrollTo.functions.scroll = function (e) {
    e.preventDefault();
    var toId = e.target.getAttribute('href') ? e.target.getAttribute('href') : e.target.getAttribute('ambient-scroll-to');
    var padding = e.target.getAttribute('ambient-scroll-to-padding') ? e.target.getAttribute('ambient-scroll-to-padding') : 0;

    if (toId.charAt(0) === '#') {
        toId = toId.substr(1, toId.length);
    }

    e.target.dispatchEvent(new CustomEvent('AmbientScrollToStarted', { bubbles: true, detail: { elem: e.target, toId: toId } }));

    _ambient.scrollTo.functions.utils.scrollVerticalToElementById(toId, padding, e.target);
}

// Scroll To - remove event listeners
_ambient.scrollTo.functions.removeEventListeners = function () {
    Object.keys(_ambient.scrollTo.elements.scrollTo).forEach(function (idx) {
        _ambient.scrollTo.elements.scrollTo[idx].removeEventListener('click', _ambient.scrollTo.functions.scroll);
    });

    window.removeEventListener('scroll', _ambient.scrollTo.functions.setTopOffset);

    Object.keys(_ambient.scrollTo.elements.scrollToTop).forEach(function (idx) {
        _ambient.scrollTo.elements.scrollToTop[idx].removeEventListener('click', _ambient.scrollTo.functions.scrollToTop);
    });
}

// Scroll To - add event listeners
_ambient.scrollTo.functions.addEventListeners = function () {
    Object.keys(_ambient.scrollTo.elements.scrollTo).forEach(function (idx) {
        _ambient.scrollTo.elements.scrollTo[idx].addEventListener('click', _ambient.scrollTo.functions.scroll);
    });

    window.addEventListener('scroll', _ambient.scrollTo.functions.setTopOffset);

    Object.keys(_ambient.scrollTo.elements.scrollToTop).forEach(function (idx) {
        _ambient.scrollTo.elements.scrollToTop[idx].addEventListener('click', _ambient.scrollTo.functions.scrollToTop);
    });
}

// Scroll To - Initializer function
_ambient.scrollTo.functions.init = function () {
    _ambient.scrollTo.functions.removeEventListeners();

    _ambient.scrollTo.elements.scrollTo = document.querySelectorAll('[ambient-scroll-to]');
    _ambient.scrollTo.elements.scrollToTop = document.querySelectorAll('[ambient-scroll-to-top-button]');

    _ambient.scrollTo.functions.addEventListeners();
}

// Adds initializer function to HTML observer
_ambient.htmlObserver.addFunction(_ambient.scrollTo.functions.init);
