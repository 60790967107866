// ======Ambient Panel======

if (_ambient.panel == undefined) _ambient.panel = {};
if (_ambient.panel.functions == undefined) _ambient.panel.functions = {};

// Sets initial panel variables
_ambient.panel.panelHeaders = document.querySelectorAll('[ambient-panel-collapsible]');

// Function to check if keypress is 'Enter' key
_ambient.panel.functions.isEnter = function (e) {
    if (e.key && e.key === 'Enter') {
        _ambient.panel.functions.togglePanel(e);
    }
}

// Panel toggle function
_ambient.panel.functions.togglePanel = function (e) {
    var exceptionElement = _ambient.core.functions.findClosestParent(e.target, null, [], ['ambient-prevent-default']);
    var eventOpen = false;

    if (!exceptionElement) {
        var parent = _ambient.core.functions.findClosestParent(e.target, 'div', [], ['ambient-panel-collapsible']);
        var panel = _ambient.core.functions.findClosestParent(e.target, 'ambient-panel', ['card-flex'], []);
        if (parent) {
            if (parent.getAttribute('aria-expanded') === 'true') {
                parent.setAttribute('aria-expanded', 'false');
                if (panel) {
                    panel.classList.add('display-block');
                }
                eventOpen = false;
            } else {
                parent.setAttribute('aria-expanded', 'true');
                if (panel) {
                    panel.classList.remove('display-block');
                }
                eventOpen = true;
            }
            parent.dispatchEvent(new CustomEvent('AmbientPanelToggle', { bubbles: true, detail: { elem: parent, open: eventOpen } }));
        }
    }
}

// Initializer function for panel
_ambient.panel.functions.init = function () {
    Object.keys(_ambient.panel.panelHeaders).forEach(function (idx) {
        _ambient.panel.panelHeaders[idx].removeEventListener('click', _ambient.panel.functions.togglePanel);
        _ambient.panel.panelHeaders[idx].removeEventListener('keypress', _ambient.panel.functions.isEnter);
    })

    _ambient.panel.panelHeaders = document.querySelectorAll('[ambient-panel-collapsible]');

    Object.keys(_ambient.panel.panelHeaders).forEach(function (idx) {
        _ambient.panel.panelHeaders[idx].addEventListener('click', _ambient.panel.functions.togglePanel);
        _ambient.panel.panelHeaders[idx].addEventListener('keypress', _ambient.panel.functions.isEnter);
    })
}

// Adds initializer function to HTML observer
_ambient.htmlObserver.addFunction(_ambient.panel.functions.init);
