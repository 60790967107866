// ======Ambient Datepicker======

var href = { doNothing: 'javascript:void(0);' };

if (_ambient === undefined) var _ambient = {};
if (_ambient.datepicker === undefined) _ambient.datepicker = {};
if (_ambient.datepicker.functions === undefined) _ambient.datepicker.functions = {};
if (_ambient.datepicker.vars === undefined) _ambient.datepicker.vars = {};
if (_ambient.datepicker.vars.date === undefined) _ambient.datepicker.vars.date = _ambient.core.functions.clearTime(new Date());
if (_ambient.datepicker.vars.months === undefined) _ambient.datepicker.vars.months = {};
if (_ambient.datepicker.vars.months.list === undefined) _ambient.datepicker.vars.months.list = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
if (_ambient.datepicker.vars.months.abbr === undefined) _ambient.datepicker.vars.months.abbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
if (_ambient.datepicker.vars.weekdays === undefined) _ambient.datepicker.vars.weekdays = {};
if (_ambient.datepicker.vars.weekdays.headers === undefined) _ambient.datepicker.vars.weekdays.headers = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
if (_ambient.datepicker.vars.weekdays.list === undefined) _ambient.datepicker.vars.weekdays.list = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
if (_ambient.datepicker.vars.weekdays.abbr === undefined) _ambient.datepicker.vars.weekdays.abbr = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];

_ambient.datepicker.functions.initialize = function () {
    const datepickerInputs = document.querySelectorAll('INPUT[ambient-date-picker]');
    for (var i = 0; i < datepickerInputs.length; i++) {
        _ambient.datepicker.functions.setup_datepicker(datepickerInputs[i]);
    }

    const daterangeInputs = document.querySelectorAll('INPUT[ambient-date-range]');
    for (var i = 0; i < daterangeInputs.length; i++) {
        _ambient.datepicker.functions.setup_daterange(daterangeInputs[i]);
    }

    const datepickers = document.querySelectorAll('DIV.ambient-calendar');
    for (var i = 0; i < datepickers.length; i++) {
        var inputFound = false;
        for (var j = 0; j < datepickerInputs.length; j++) {
            if (datepickerInputs[j].id + '_cal' === datepickers[i].id) {
                inputFound = true;
            }
        }
        for (var k = 0; k < daterangeInputs.length; k++) {
            if (daterangeInputs[k].id + '_cal' === datepickers[i].id) {
                inputFound = true;
            }
        }
        if (!inputFound) {
            document.querySelector('body').removeChild(datepickers[i]);
        }
    }
};

_ambient.datepicker.functions.setInteractiveFunctions = function (datepicker) {
    if (!datepicker.field) return;

    datepicker.field.show = function () {
        _ambient.datepicker.functions.showCalendar(datepicker);
    }

    datepicker.field.hide = function () {
        _ambient.datepicker.functions.hideCalendar(datepicker);
    }
};

_ambient.datepicker.functions.report_window_resize = function (datepicker) {
    if (datepicker.calendar.style.cssText.indexOf('visibility: visible') != -1) {
        _ambient.datepicker.functions.display_calendar(datepicker, 'visible');
    }
}

_ambient.datepicker.functions.setup_datepicker = function (field) {
    if (field.getAttribute('_ambient.datepicker.functions')) return; // Check if already initialized.
    field.setAttribute('_ambient.datepicker.functions', true); // Mark as initializing.
    field.setAttribute('ambient-datepicker-input', true); // Mark as initializing.
    var datepicker = _ambient.datepicker.functions.createSettingsObject(field);
    datepicker.singleSelect = true;
    _ambient.datepicker.functions.attachEvents(datepicker);
    _ambient.datepicker.functions.create_calendarElements(datepicker);
    if (field.hasAttribute('ambient-date-picker-create-clear-btn')) {
        _ambient.datepicker.functions.create_clearButton(datepicker, field);
        _ambient.datepicker.functions.clearButton_hide_show_init(field)
    }
    _ambient.datepicker.functions.changeMonth(datepicker, 0);
    datepicker.initialized = true;
    _ambient.datepicker.functions.setInteractiveFunctions(datepicker);
};

_ambient.datepicker.functions.setup_daterange = function (field) {
    if (field.getAttribute('_ambient.datepicker.functions')) return; // Check if already initialized.
    field.setAttribute('_ambient.datepicker.functions', true); // Mark as initializing.
    var datepicker = _ambient.datepicker.functions.createSettingsObject(field);
    datepicker.singleSelect = false;
    // if (datepicker.button) {

    // }
    _ambient.datepicker.functions.attachEvents(datepicker);
    _ambient.datepicker.functions.create_calendarElements(datepicker);
    if (field.hasAttribute('ambient-date-picker-create-clear-btn')) {
        _ambient.datepicker.functions.create_clearButton(datepicker, field);
        _ambient.datepicker.functions.clearButton_hide_show_init(field)
    }
    _ambient.datepicker.functions.changeMonth(datepicker, 0);
    datepicker.initialized = true;
    _ambient.datepicker.functions.setInteractiveFunctions(datepicker);
};

_ambient.datepicker.functions.showCalendar = function (datepicker) {
    setTimeout(function () {
        if (datepicker.start && datepicker.view.getMonth() !== datepicker.start.getMonth()) {
            datepicker.view = new Date(datepicker.start);
            _ambient.datepicker.functions.changeMonth(datepicker, 0);
        }
        _ambient.datepicker.functions.display_calendar(datepicker, 'visible');
    }, 100);
};

_ambient.datepicker.functions.hideCalendar = function (datepicker) {
    setTimeout(function () {
        const elem = _ambient.core.functions.findClosestParent(document.activeElement, 'div', ['ambient-calendar'], []);
        if (!elem) {
            _ambient.datepicker.functions.display_calendar(datepicker, 'hidden');
        }
    }, 100);
};

_ambient.datepicker.functions.attachEvents = function (datepicker) {
    if (!datepicker.field.hasAttribute('disable-listeners')) {
        datepicker.field.addEventListener('focus', function () {
            if (datepicker.calendar.style.visibility !== 'visible' && datepicker.applyCalendarClicked == false) {
                _ambient.datepicker.functions.showCalendar(datepicker);
            }
            if (datepicker.applyCalendarClicked == true) datepicker.applyCalendarClicked = false
        });

        datepicker.field.addEventListener('click', function () {
            if (datepicker.calendar.style.visibility !== 'visible') {
                _ambient.datepicker.functions.showCalendar(datepicker);
            }
        });

        if (!datepicker.field.hasAttribute('disable-input-blur')) {
            datepicker.field.addEventListener('blur', function (e) {
                _ambient.datepicker.functions.hideCalendar(datepicker);
            });
        }

        datepicker.field.addEventListener('keydown', function (e) {
            if (e.keyCode == 9) {
                datepicker.calendar.setAttribute('tabindex', '0');
                datepicker.calendar.focus();
            }
        });
    }

    window.addEventListener('resize', function () {
        _ambient.datepicker.functions.report_window_resize(datepicker);
    });
};

_ambient.datepicker.functions.createSettingsObject = function (field) {
    var datepicker = {
        field: field,
        calendar: null,
        singleSelect: true,
        cal_table1: null,
        cal_table2: null,
        prevLink: null,
        nextLink: null,
        applyLink: null,
        applyCalendarClicked: false,
        view: _ambient.core.functions.clearTime(new Date()),
        start: null,
        end: null,
        // button: (field.hasAttribute('date-button') || false),
        // buttonDisplay: (field.getAttribute('date-button-display') || null),
        // responsive: (field.hasAttribute('date-responsive') || false),
        initialized: false,
        initialSelectionMade: false,
        format: (field.getAttribute('date-format') || 'm/d/yyyy'),
        yearRange: {
            yearsForward: null,
            yearsBackward: null,
        },
        limit: {
            start: null, // This will be updated with value on Element before display.
            end: null // This will be updated with value on Element before display.
        },
        output: {
            start: (field.getAttribute('output-start') ? document.getElementById(field.getAttribute('output-start')) : null),
            end: (field.getAttribute('output-end') ? document.getElementById(field.getAttribute('output-end')) : null),
            format: (field.getAttribute('output-format') || 'yyyy-mm-dd')
        }
    };
    return datepicker;
};

_ambient.datepicker.functions.updateDateAttribute = function (datepicker, attr) {
    datepicker.limit[attr] = (datepicker.field.getAttribute(attr) ? (/^today$/i.test(datepicker.field.getAttribute(attr)) ? new Date() : new Date(datepicker.field.getAttribute(attr))) : null)
};

_ambient.datepicker.functions.updateYearRangeAttribute = function (datepicker, attr) {
    datepicker.yearRange[attr] = datepicker.field.getAttribute(attr) ? datepicker.yearRange.attr = parseInt(datepicker.field.getAttribute(attr)) : 20;
};

_ambient.datepicker.functions.offset = function (el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

_ambient.datepicker.functions.datePickerFocus = function (datepicker) {
    datepicker.calendar.setAttribute('tabindex', '0');
    datepicker.calendar.focus();
};

_ambient.datepicker.functions.display_calendar = function (datepicker, visibility) {
    //display calendar based on field
    if (visibility !== 'hidden') {
        var position = _ambient.datepicker.functions.offset(datepicker.field);
        var bodyHeight = document.body.offsetHeight;
        var bodyWidth = document.body.offsetWidth;
        var fieldHeight = datepicker.field.offsetHeight;
        var fieldWidth = datepicker.field.offsetWidth;
        var calendarHeight = datepicker.calendar.offsetHeight;
        var calendarWidth = datepicker.calendar.offsetWidth;
        var fieldPositionRight = position.left + fieldWidth;
        var fieldPositionBottom = position.top + fieldHeight;
        var differenceBetweenFieldWidthAndCalendarWidth = calendarWidth - fieldWidth;
        var spaceFromEndOfFieldToRightOfBody = bodyWidth - fieldPositionRight;
        var spaceFromBottomOfFieldToBottomOfBody = bodyHeight - fieldPositionBottom;

        var marginFromField = 0;
        if (datepicker.field.hasAttribute('ambient-date-picker-margin')) {
            //marginFromField is set with the attribute ambient-date-picker-margin="". If it's not set it will be 0
            var ambientDatePickerMargin = parseInt(datepicker.field.getAttribute('ambient-date-picker-margin'))
            marginFromField = ambientDatePickerMargin
        }
        var calendarPlacementFromTop = position.top + fieldHeight + marginFromField + 'px';
        var calendarPlacementFromBottom = position.top - calendarHeight - marginFromField + 'px';

        if (calendarWidth > fieldWidth && bodyWidth > calendarWidth + spaceFromEndOfFieldToRightOfBody) {
            if (differenceBetweenFieldWidthAndCalendarWidth > spaceFromEndOfFieldToRightOfBody) {
                datepicker.calendar.style.top = calendarPlacementFromTop;
                datepicker.calendar.style.right = spaceFromEndOfFieldToRightOfBody + 'px';
                datepicker.calendar.style.left = null;
            } else {
                datepicker.calendar.style.top = calendarPlacementFromTop;
                datepicker.calendar.style.left = position.left + 'px';
                datepicker.calendar.style.right = null;
            }
        } else {
            datepicker.calendar.style.top = calendarPlacementFromTop;
            datepicker.calendar.style.left = position.left + 'px';
            datepicker.calendar.style.right = null;
        }

        if (calendarHeight > spaceFromBottomOfFieldToBottomOfBody) {
            datepicker.calendar.style.top = calendarPlacementFromBottom;
        }

        _ambient.datepicker.functions.loadExistingValues(datepicker); // Check if textbox value has been typed into.
        _ambient.datepicker.functions.changeMonth(datepicker, 0); // Update view in case the min or max values have been updated.
        if (!datepicker.field.hasAttribute('disable-click-outside')) _ambient.datepicker.functions.hideIfClickOutsideDatePicker(datepicker);
        // _ambient.datepicker.functions.datePickerFocus(datepicker);

        _ambient.datepicker.functions.triggerOpen(datepicker);
    } else {
        _ambient.datepicker.functions.triggerClose(datepicker);
    }
    datepicker.calendar.style.visibility = visibility;
};

_ambient.datepicker.functions.hideIfClickOutsideDatePicker = function (datepicker, callback) {
    // Create event to close the calendar if the user clicks away.
    const outsideClickListener = function (event) {
        const parent = datepicker.field.parentNode;
        if (!datepicker.calendar.contains(event.target) && !parent.contains(event.target)) {
            _ambient.datepicker.functions.display_calendar(datepicker, 'hidden');
            removeClickListener();
            if (callback) callback();
        }
    };
    const removeClickListener = function () {
        document.removeEventListener('click', outsideClickListener)
    };
    document.addEventListener('click', outsideClickListener);
};

_ambient.datepicker.functions.loadExistingValues = function (datepicker) {
    if (datepicker.singleSelect) {
        if (datepicker.field.value) {
            datepicker.start = new Date(datepicker.field.value);
        } else if (datepicker.output.start && datepicker.output.start.value) {
            datepicker.start = new Date(datepicker.output.start.value);
            _ambient.datepicker.functions.applyCalendar(datepicker);
        }
    } else {
        if (datepicker.field.value) {
            const split = datepicker.field.value.split(' - ');
            if (split && split[0] && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(split[0])) {
                datepicker.start = new Date(split[0]);
            } else {
                datepicker.start = null;
            }
            if (split && split[1] && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(split[1])) {
                datepicker.end = new Date(split[1]);
            } else {
                datepicker.end = null;
            }
        } else {
            datepicker.start = null;
            datepicker.end = null;
        }
        if (datepicker.start && datepicker.end) {
            _ambient.datepicker.functions.applyCalendar(datepicker);
        }
    }
    if (datepicker.start) datepicker.view = new Date(datepicker.start);
};

_ambient.datepicker.functions.create_calendarElements = function (datepicker) {
    const body = document.querySelector('body');
    const cal_div = document.createElement('DIV');
    cal_div.id = datepicker.field.id + '_dp';
    cal_div.setAttribute('ambient-date-picker-control', '');
    const cal_node = document.createElement("DIV");
    datepicker.calendar = cal_node;
    cal_node.id = datepicker.field.id + '_cal';
    cal_node.className = 'ambient-calendar';
    cal_node.style.top = 0;
    if (datepicker.singleSelect) {
        const cal_table = document.createElement("TABLE");
        datepicker.cal_table1 = cal_table;
        _ambient.datepicker.functions.build_cal_row_title(datepicker);
        _ambient.datepicker.functions.build_cal_row_head(datepicker);
        _ambient.datepicker.functions.build_cal_body(datepicker);
        cal_node.appendChild(cal_table);
    } else {
        const cal_tables = document.createElement("DIV");
        cal_tables.className = "date-range";
        const cal_table1 = document.createElement("TABLE");
        datepicker.cal_table1 = cal_table1;
        _ambient.datepicker.functions.build_cal_row_title(datepicker, 0);
        _ambient.datepicker.functions.build_cal_row_head(datepicker, 0);
        _ambient.datepicker.functions.build_cal_body(datepicker, 0);
        cal_tables.appendChild(cal_table1);
        const cal_table2 = document.createElement("TABLE");
        datepicker.cal_table2 = cal_table2;
        _ambient.datepicker.functions.build_cal_row_title(datepicker, 1);
        _ambient.datepicker.functions.build_cal_row_head(datepicker, 1);
        _ambient.datepicker.functions.build_cal_body(datepicker, 1);
        cal_tables.appendChild(cal_table2);
        cal_node.appendChild(cal_tables);
        _ambient.datepicker.functions.build_cal_footer(datepicker);
    }
    datepicker.field.parentNode.insertBefore(cal_div, datepicker.field);
    const cal_icon = document.createElement("I");
    cal_icon.className = "far fa-calendar-alt";
    cal_div.appendChild(cal_icon);
    cal_div.appendChild(datepicker.field);
    // cal_div.appendChild(cal_node);
    body.appendChild(cal_node);

    _ambient.datepicker.functions.loadExistingValues(datepicker);
};

_ambient.datepicker.functions.create_clearButton = function (datepicker, field) {
    const clear_button = document.createElement('DIV');
    clear_button.setAttribute('ambient-date-picker-clear-btn', '');
    var clear_button_height = field.offsetHeight + 'px';

    clear_button.classList.add('fas');
    clear_button.classList.add('fa-times');
    clear_button.style.height = clear_button_height;

    clear_button.addEventListener('click', function () {
        datepicker.field.value = '';
        _ambient.datepicker.functions.clearButton_hide(datepicker);
    });

    field.parentNode.insertBefore(clear_button, field.nextSibling);
    field.addEventListener('input', function (e) {
        if (e.target.value == '') {
            _ambient.datepicker.functions.clearButton_hide(datepicker);
        }
    });
};

_ambient.datepicker.functions.build_cal_row_title = function (datepicker, index) {
    const cal_table = (index === 1 ? datepicker.cal_table2 : datepicker.cal_table1);

    const tr_title = document.createElement("TR");
    tr_title.className = 'cal-title';

    const th_prev = document.createElement("TD");
    if (datepicker.singleSelect || index === 0) {
        const a_left = document.createElement("A");
        a_left.href = href.doNothing;
        a_left.className = "far fa-angle-left";
        a_left.setAttribute('aria-label', 'Previous Month');
        a_left.setAttribute('previous-month-nav', '');
        a_left.addEventListener('click', function () {
            _ambient.datepicker.functions.changeMonth(datepicker, -1);
        });
        datepicker.prevLink = a_left;
        th_prev.appendChild(a_left);
    }
    tr_title.appendChild(th_prev);

    const th_title = document.createElement("TH");
    th_title.setAttribute('colspan', 5);
    th_title.className = 'month';
    _ambient.datepicker.functions.build_cal_month_dropdown(datepicker, th_title, index);
    _ambient.datepicker.functions.build_cal_year_dropdown(datepicker, th_title, index);
    tr_title.appendChild(th_title);

    const th_next = document.createElement("TD");
    if (datepicker.singleSelect || index === 1) {
        const a_right = document.createElement("A");
        a_right.href = href.doNothing;
        a_right.className = "far fa-angle-right";
        a_right.setAttribute('aria-label', 'Next Month');
        a_right.setAttribute('next-month-nav', '');
        a_right.addEventListener('click', function () {
            _ambient.datepicker.functions.changeMonth(datepicker, 1);
        });
        datepicker.nextLink = a_right;
        th_next.appendChild(a_right);
    }
    tr_title.appendChild(th_next);

    cal_table.appendChild(tr_title);
};

_ambient.datepicker.functions.build_cal_month_dropdown = function (datepicker, th, index) {
    if (index === undefined) index = 0;
    const a_month = document.createElement("A");
    a_month.href = href.doNothing;
    a_month.className = "custom-dropdown";
    const span_month = document.createElement('SPAN');
    span_month.className = "month-year";
    span_month.innerHTML = _ambient.datepicker.vars.months.list[datepicker.view.getMonth() + index];
    a_month.appendChild(span_month);
    const ul_month = document.createElement("UL");
    ul_month.style.display = 'none';
    for (var i = 0; i < _ambient.datepicker.vars.months.list.length; i++) {
        const li_month = document.createElement("LI");
        const a_li_month = document.createElement("A");
        const id = parseInt(i) - index;
        a_li_month.innerHTML = _ambient.datepicker.vars.months.list[i];
        a_li_month.href = href.doNothing;
        a_li_month.addEventListener('click', function (e) {
            _ambient.datepicker.functions.selectMonth(datepicker, li_month, id);
            e.stopPropagation();
        });
        li_month.appendChild(a_li_month);
        ul_month.appendChild(li_month);
    }
    /* Apply Focus Lock for the menu */
    _ambient.datepicker.functions.menuFocusLock(ul_month);

    a_month.addEventListener('click', function () {
        if (_ambient.core.functions.isVisible(ul_month)) ul_month.style.display = 'none';
        else {
            _ambient.datepicker.functions.validateMonthOptions(datepicker, index);
            ul_month.style.display = '';
            if (ul_month.querySelector('LI.selected')) ul_month.scrollTop = ul_month.querySelector('LI.selected').offsetTop;
            else ul_month.scrollTop = 0;
            _ambient.core.functions.hideIfClickOutside(ul_month);
        }
    });
    a_month.appendChild(ul_month);
    th.appendChild(a_month);
    if (datepicker.controls === undefined) datepicker.controls = [];
    datepicker.controls[index] = {};
    datepicker.controls[index].month = a_month;
};

_ambient.datepicker.functions.build_cal_year_dropdown = function (datepicker, th, index) {
    if (index === undefined) index = 0;
    const a_year = document.createElement("A");
    a_year.href = href.doNothing;
    a_year.className = 'custom-dropdown';
    const span_year = document.createElement('SPAN');
    span_year.className = 'month-year';
    span_year.innerHTML = datepicker.view.getFullYear();
    a_year.appendChild(span_year);
    const ul_year = document.createElement("UL");
    ul_year.style.display = 'none';
    _ambient.datepicker.functions.updateDateAttribute(datepicker, 'start');
    _ambient.datepicker.functions.updateDateAttribute(datepicker, 'end');
    _ambient.datepicker.functions.updateYearRangeAttribute(datepicker, 'yearsForward');
    _ambient.datepicker.functions.updateYearRangeAttribute(datepicker, 'yearsBackward');
    var earliest = datepicker.limit.start ? datepicker.limit.start.getFullYear() : ((new Date()).getFullYear() - datepicker.yearRange.yearsBackward);
    var latest = datepicker.limit.end ? datepicker.limit.end.getFullYear() : ((new Date()).getFullYear() + datepicker.yearRange.yearsForward);
    for (var i = latest; i >= earliest; i--) {
        const li_year = document.createElement("LI");
        const a_li_year = document.createElement("A");
        const index = parseInt(i);
        a_li_year.innerHTML = i;
        a_li_year.href = href.doNothing;
        a_li_year.addEventListener('click', function (e) {
            _ambient.datepicker.functions.selectYear(datepicker, li_year, index);
            e.stopPropagation();
        });
        li_year.appendChild(a_li_year);
        if (datepicker.field.hasAttribute('ambient-date-picker-year-sort') && datepicker.field.getAttribute('ambient-date-picker-year-sort') == 'ascending') {
            ul_year.insertBefore(li_year, ul_year.firstChild)
        } else {
            ul_year.appendChild(li_year);
        }

    }
    /* Apply Focus Lock for the menu */
    _ambient.datepicker.functions.menuFocusLock(ul_year);

    a_year.addEventListener('click', function () {
        if (_ambient.core.functions.isVisible(ul_year)) ul_year.style.display = 'none';
        else {
            _ambient.datepicker.functions.validateYearOptions(datepicker, index);
            ul_year.style.display = '';
            if (ul_year.querySelector('LI.selected')) ul_year.scrollTop = ul_year.querySelector('LI.selected').offsetTop;
            else ul_year.scrollTop = 0;
            _ambient.core.functions.hideIfClickOutside(ul_year);
        }
    });
    a_year.appendChild(ul_year);
    th.appendChild(a_year);
    datepicker.controls[index].year = a_year;
};

_ambient.datepicker.functions.validateMonthOptions = function (datepicker, index) {
    var date = new Date(datepicker.view);
    var options = datepicker.controls[index].month.children[1].children;
    _ambient.datepicker.functions.updateDateAttribute(datepicker, 'start');
    _ambient.datepicker.functions.updateDateAttribute(datepicker, 'end');
    var start = datepicker.limit.start ? datepicker.limit.start.getTime() : null;
    var end = datepicker.limit.end ? datepicker.limit.end.getTime() : null;
    var a;
    var month = 30 * 24 * 60 * 60 * 1000;
    for (var i = 0; i < options.length; i++) {
        date.setMonth(i);
        a = options[i].children[0];
        if ((start != null && date.getTime() + month < start) || (end != null && date.getTime() >= end)) a.classList.add('disabled');
        else a.className = a.classList.remove('disabled');
    }
};

_ambient.datepicker.functions.validateYearOptions = function (datepicker, index) {
    var yearStart = new Date(datepicker.view);
    var yearEnd = new Date(datepicker.view);
    var options = datepicker.controls[index].year.children[1].children;
    _ambient.datepicker.functions.updateDateAttribute(datepicker, 'start');
    _ambient.datepicker.functions.updateDateAttribute(datepicker, 'end');
    var start = datepicker.limit.start ? datepicker.limit.start.getTime() : null;
    var end = datepicker.limit.end ? datepicker.limit.end.getTime() : null;
    var a;
    for (var i = 0; i < options.length; i++) {
        a = options[i].children[0];
        yearStart.setYear(a.innerHTML); yearStart.setMonth(0); yearStart.setDate(1);
        yearEnd.setYear(a.innerHTML); yearEnd.setMonth(11); yearEnd.setDate(31);
        if ((start != null && yearEnd.getTime() < start) || (end != null && yearStart.getTime() >= end)) a.classList.add('disabled');
        else a.className = a.classList.remove('disabled');
    }
};

_ambient.datepicker.functions.menuFocusLock = function (ul) {
    const first = ul.children[0].children[0];
    const last = ul.children[ul.children.length - 1].children[0];
    _ambient.core.functions.focusLock(first, last);
};

_ambient.datepicker.functions.selectMonth = function (datepicker, li, index) {
    // Hide custom dropdown
    li.parentNode.style.display = 'none';
    // Update calendar with selected Month
    datepicker.view.setMonth(index);
    _ambient.datepicker.functions.changeMonth(datepicker, 0);
};

_ambient.datepicker.functions.selectYear = function (datepicker, li, index) {
    // Hide custom dropdown
    li.parentNode.style.display = 'none';
    // Update calendar with selected Month
    datepicker.view.setYear(index);
    _ambient.datepicker.functions.changeMonth(datepicker, 0);
};

_ambient.datepicker.functions.updateMonthYear = function (datepicker) {
    var count = datepicker.singleSelect ? 1 : 2;
    var viewMonth = datepicker.view.getMonth();
    var viewYear = datepicker.view.getFullYear();
    var control, options;
    for (var index = 0; index < count; index++) {
        // Update selected Month
        control = datepicker.controls[index].month;
        control.children[0].innerHTML = _ambient.datepicker.vars.months.list[viewMonth];
        options = control.querySelectorAll('LI');
        for (var i = 0; i < options.length; i++) options[i].className = (i === viewMonth ? 'selected' : '');
        // Update selected Year
        control = datepicker.controls[index].year;
        control.children[0].innerHTML = viewYear;
        options = control.querySelectorAll('LI');
        for (i = 0; i < options.length; i++) options[i].className = (options[i].children[0].innerHTML === viewYear ? 'selected' : '');
        // Prepare for next index
        viewMonth++;
        if (viewMonth === 12) {
            viewMonth = 0;
            viewYear++;
        }
    }
};

_ambient.datepicker.functions.build_cal_row_head = function (datepicker, index) {
    const cal_table = (index === 1 ? datepicker.cal_table2 : datepicker.cal_table1);
    const tr_head = document.createElement("TR");
    tr_head.className = 'week-label';

    _ambient.datepicker.vars.weekdays.headers.forEach(function (day) {
        const th_day = document.createElement('TH');
        th_day.innerHTML = (day);
        tr_head.appendChild(th_day);
    });

    cal_table.appendChild(tr_head);
};

_ambient.datepicker.functions.build_cal_body = function (datepicker, index) {
    const cal_table = (index === 1 ? datepicker.cal_table2 : datepicker.cal_table1);
    // Clear calendar, if necessary
    const weeks = cal_table.querySelectorAll('TR.week');
    for (var i = 0; i < weeks.length; i++) {
        cal_table.removeChild(weeks[i]);
    }
    // Build calendar
    const now = _ambient.core.functions.clearTime(new Date());
    var date = new Date(datepicker.view);
    date.setDate(1);
    date.setMonth(date.getMonth() + (index || 0));
    var currMonth = date.getMonth();
    date.setDate(date.getDate() - date.getDay());
    do {
        const tr = document.createElement("TR");
        tr.className = 'week';
        _ambient.datepicker.vars.weekdays.list.forEach(function (day) {
            const td = document.createElement("TD");
            const a = document.createElement("A");
            const selectDate = new Date(date); // Store cell date for selection.
            var isCurrent = date.getMonth() === currMonth;
            td.setAttribute('data-date', selectDate);
            a.innerHTML = date.getDate();
            a.setAttribute('aria-label', _ambient.datepicker.vars.months.list[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear());
            td.className = (isCurrent ? 'curr-month' : 'off-month');
            if (isCurrent) {
                _ambient.datepicker.functions.updateDateAttribute(datepicker, 'start');
                _ambient.datepicker.functions.updateDateAttribute(datepicker, 'end');
                if (datepicker.limit.start && date < datepicker.limit.start) td.classList.add('past');
                else if (datepicker.limit.end && date > datepicker.limit.end) td.classList.add('past');
                else {
                    if (datepicker.start && date.getTime() === datepicker.start.getTime() || (datepicker.end && date.getTime() === datepicker.end.getTime())) td.classList.add('selected');
                    if (date > datepicker.start && date < datepicker.end) td.classList.add('between');

                    let calcDate = undefined;

                    if (datepicker.start) {
                        let seventhDate = new Date(datepicker.start);
                        calcDate = new Date(seventhDate.setDate(seventhDate.getDate() + 7));
                    }

                    if (calcDate && date >= calcDate && date < datepicker.end) td.classList.add('border-standard');

                    a.href = href.doNothing;
                    a.addEventListener('click', function (e) {
                        _ambient.datepicker.functions.selectCalDate(datepicker, selectDate);
                    });
                }
            }
            td.appendChild(a);
            tr.appendChild(td);
            date.setDate(date.getDate() + 1);
        });
        cal_table.appendChild(tr);
    } while (date.getMonth() === currMonth)
};

_ambient.datepicker.functions.build_cal_footer = function (datepicker) {
    const cal_table = datepicker.cal_table1;
    const div_foot = document.createElement("DIV");
    div_foot.className = 'cal-footer';

    const a_clear = document.createElement("A");
    a_clear.href = href.doNothing;
    a_clear.className = "cal-clear";
    a_clear.addEventListener('click', function () {
        _ambient.datepicker.functions.clearCalendar(datepicker);
        _ambient.datepicker.functions.clearButton_hide(datepicker);
        _ambient.datepicker.functions.triggerClear(datepicker);
    });
    a_clear.innerHTML = ('Clear');
    div_foot.appendChild(a_clear);

    const a_apply = document.createElement("A");
    a_apply.href = href.doNothing;
    a_apply.className = "cal-apply";
    a_apply.addEventListener('click', function () {
        _ambient.datepicker.functions.applyCalendar(datepicker)
        _ambient.datepicker.functions.triggerApply(datepicker);
    });
    a_apply.innerHTML = ('Apply');
    div_foot.appendChild(a_apply);
    datepicker.applyLink = a_apply;

    /* Add focus lock on the calendar */
    _ambient.core.functions.focusLock(datepicker.prevLink, datepicker.applyLink);

    cal_table.parentElement.appendChild(div_foot);
};

_ambient.datepicker.functions.changeMonth = function (datepicker, direction) {
    const cal_table = datepicker.cal_table1;
    _ambient.datepicker.functions.updateDateAttribute(datepicker, 'start');
    _ambient.datepicker.functions.updateDateAttribute(datepicker, 'end');
    var min_view = datepicker.limit.start ? new Date(datepicker.limit.start) : null;
    var max_view = datepicker.limit.end ? new Date(datepicker.limit.end) : null;
    if (min_view) {
        min_view.setDate(1);
        min_view.setMonth(min_view.getMonth() - 1);
    }
    if (max_view) max_view.setDate(1);
    datepicker.view.setDate(1);
    datepicker.view.setMonth(datepicker.view.getMonth() + direction);
    if ((min_view && datepicker.view.getTime() < min_view.getTime()) || (max_view && datepicker.view.getTime() > max_view.getTime())) {
        datepicker.view.setMonth(datepicker.view.getMonth() - direction);
    }
    if (datepicker.singleSelect) _ambient.datepicker.functions.build_cal_body(datepicker);
    else {
        var tables = cal_table.parentNode.querySelectorAll('TABLE');
        for (var i = 0; i < tables.length; i++) {
            _ambient.datepicker.functions.build_cal_body(datepicker, i);
        }
    }
    // Update controls
    _ambient.datepicker.functions.updateMonthYear(datepicker);
    // Determine if Previous and Next month buttons should be available.
    var prev = new Date(datepicker.view);
    prev.setMonth(prev.getMonth() - 1);
    datepicker.prevLink.style.visibility = (min_view && prev.getTime() < min_view.getTime()) ? 'hidden' : '';
    var next = new Date(datepicker.view);
    next.setMonth(next.getMonth() + 1);
    datepicker.nextLink.style.visibility = (max_view && next.getTime() > max_view.getTime()) ? 'hidden' : '';
};

_ambient.datepicker.functions.selectCalDate = function (datepicker, selectDate) {
    _ambient.datepicker.functions.updateDatepickerDate(datepicker, selectDate);
    _ambient.datepicker.functions.updateCalendarSelection(datepicker);
    if (datepicker.singleSelect) _ambient.datepicker.functions.applyCalendar(datepicker);
};

_ambient.datepicker.functions.clearButton_hide_show_init = function (field) {
    var datepicker_input = field;
    var datepicker_close_btn = field.nextSibling;

    if (datepicker_input.value != '' && !datepicker_input.hasAttribute('disabled')) {
        datepicker_close_btn.classList.remove('display-none');
    } else {
        datepicker_close_btn.classList.add('display-none');
    }
}

_ambient.datepicker.functions.clearButton_hide = function (datepicker) {
    var ambient_date_picker_control = _ambient.core.functions.findClosestParent(datepicker.field, 'div', [], ['ambient-date-picker-control']);
    var ambient_date_picker_input = _ambient.core.functions.findClosestParent(datepicker.field, 'input', [], ['_ambient.datepicker.functions']);
    // var ambient_date_picker_clear_btn = ambient_date_picker_control.querySelector('[ambient-date-picker-clear-btn]');
    var calendar_icon = ambient_date_picker_control.querySelector('.fa-calendar-alt');

    if (ambient_date_picker_input.hasAttribute('ambient-date-picker-hide-calendar-on-select') && calendar_icon && calendar_icon.classList.contains('display-none')) {
        calendar_icon.classList.remove('display-none')
    }
    // ambient_date_picker_clear_btn.classList.add('display-none');
    _ambient.datepicker.functions.clearCalendar(datepicker);
}

_ambient.datepicker.functions.clearButton_show = function (datepicker) {
    var ambient_date_picker_control = _ambient.core.functions.findClosestParent(datepicker.field, 'div', [], ['ambient-date-picker-control']);
    var clear_btn = ambient_date_picker_control.querySelector('[ambient-date-picker-clear-btn]');
    var calendar_icon = ambient_date_picker_control.querySelector('.fa-calendar-alt');
    var ambient_date_picker_input = _ambient.core.functions.findClosestParent(datepicker.field, 'input', [], ['_ambient.datepicker.functions']);
    if (ambient_date_picker_input != null && ambient_date_picker_input.value != '' && clear_btn) {
        clear_btn.classList.remove('display-none');
    }
    if (ambient_date_picker_input != null && ambient_date_picker_input.value != '' && clear_btn && ambient_date_picker_input.hasAttribute('ambient-date-picker-hide-calendar-on-select') && calendar_icon) {
        calendar_icon.classList.add('display-none');
    }
}

_ambient.datepicker.functions.updateDatepickerDate = function (datepicker, selectDate) {
    if (datepicker.singleSelect) {
        datepicker.start = selectDate;
    } else {
        if (datepicker.initialSelectionMade) {
            if (selectDate > datepicker.start) datepicker.end = selectDate;
            else {
                datepicker.end = datepicker.start;
                datepicker.start = selectDate;
            }
        } else {
            datepicker.start = selectDate;
            datepicker.end = null;
        }
        datepicker.initialSelectionMade = !datepicker.initialSelectionMade;
    }
};

_ambient.datepicker.functions.updateCalendarSelection = function (datepicker) {
    _ambient.datepicker.functions.clearCalendarSelection(datepicker);
    const days = datepicker.cal_table1.parentNode.querySelectorAll('TD.curr-month');
    for (var i = 0; i < days.length; i++) {
        var day = days[i];
        var date = new Date(day.getAttribute('data-date'));
        if (date && date.getTime() === datepicker.start.getTime()) day.classList.add('selected');
        if (datepicker.end && date.getTime() === datepicker.end.getTime()) day.classList.add('selected');
        if (date > datepicker.start && date < datepicker.end) day.classList.add('between');

        let calcDate = undefined;

        if (datepicker.start) {
            let seventhDate = new Date(datepicker.start);
            calcDate = new Date(seventhDate.setDate(seventhDate.getDate() + 7));
        }

        if (calcDate && date >= calcDate && date < datepicker.end) day.classList.add('border-standard');
    }
};

_ambient.datepicker.functions.clearCalendarSelection = function (datepicker) {
    const days = datepicker.cal_table1.parentNode.querySelectorAll('TD.curr-month');
    for (var i = 0; i < days.length; i++) {
        var day = days[i];
        day.classList.remove('selected');
        day.classList.remove('between');
        day.classList.remove('border-standard');
    }
};

_ambient.datepicker.functions.clearCalendar = function (datepicker) {
    _ambient.datepicker.functions.display_calendar(datepicker, 'hidden');
    datepicker.field.value = '';
    datepicker.view = _ambient.core.functions.clearTime(new Date());
    _ambient.datepicker.functions.changeMonth(datepicker, 0); // redraw calendar for the reset date
    datepicker.start = datepicker.end = null;
    if (datepicker.output.start) datepicker.output.start.value = '';
    if (datepicker.output.end) datepicker.output.end.value = '';
    datepicker.initialSelectionMade = false;
    _ambient.datepicker.functions.clearCalendarSelection(datepicker);
    _ambient.datepicker.functions.triggerChange(datepicker);
};

_ambient.datepicker.functions.applyCalendar = function (datepicker) {
    datepicker.applyCalendarClicked = true;
    if (datepicker.initialized) {
        datepicker.field.focus();
    }
    _ambient.datepicker.functions.display_calendar(datepicker, 'hidden');
    if (datepicker.singleSelect) {
        datepicker.field.value = _ambient.datepicker.functions.formatDate(datepicker.start, datepicker.format);
        if (datepicker.output.start) datepicker.output.start.value = _ambient.datepicker.functions.formatDate(datepicker.start, datepicker.output.format);
    } else {
        // if (!datepicker.end) datepicker.end = datepicker.start;
        datepicker.field.value = _ambient.datepicker.functions.formatDate(datepicker.start, datepicker.format) + (datepicker.end ? " - " + _ambient.datepicker.functions.formatDate(datepicker.end, datepicker.format) : '');
        if (datepicker.output.start) datepicker.output.start.value = _ambient.datepicker.functions.formatDate(datepicker.start, datepicker.output.format);
        if (datepicker.output.end) datepicker.output.end.value = _ambient.datepicker.functions.formatDate(datepicker.end, datepicker.output.format);
    }
    _ambient.datepicker.functions.triggerChange(datepicker);
    _ambient.datepicker.functions.clearButton_show(datepicker);
};

_ambient.datepicker.functions.triggerEvent = function (eventType, datepicker) {
    if (!eventType || !datepicker) return;

    if ("createEvent" in document) {
        var event = document.createEvent("HTMLEvents");
        event.detail = {
            'start': datepicker.start,
            'end': datepicker.end,
            'constraints': {
                'start': datepicker.output.start,
                'end': datepicker.output.start
            }
        };
        event.initEvent(eventType, false, true);
        datepicker.field.dispatchEvent(event);
    }
};

_ambient.datepicker.functions.triggerApply = function (datepicker) {
    _ambient.datepicker.functions.triggerEvent('apply', datepicker);
};

_ambient.datepicker.functions.triggerClear = function (datepicker) {
    _ambient.datepicker.functions.triggerEvent('clear', datepicker);
};

_ambient.datepicker.functions.triggerChange = function (datepicker) {
    _ambient.datepicker.functions.triggerEvent('change', datepicker);
};

_ambient.datepicker.functions.triggerOpen = function (datepicker) {
    _ambient.datepicker.functions.triggerEvent('open', datepicker);
};

_ambient.datepicker.functions.triggerClose = function (datepicker) {
    _ambient.datepicker.functions.triggerEvent('close', datepicker);
};

_ambient.datepicker.functions.formatDate = function (date, format) {
    var result = format;
    if (date) {
        if (/mmmm/i.test(result)) result = result.replace(/mmmm/i, _ambient.datepicker.vars.months.list[date.getMonth()]);
        if (/mmm/i.test(result)) result = result.replace(/mmm/i, _ambient.datepicker.vars.months.abbr[date.getMonth()]);
        if (/mm/i.test(result)) result = result.replace(/mm/i, (date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)));
        if (/m/i.test(result)) result = result.replace(/m/i, date.getMonth() + 1);
        if (/dddd/i.test(result)) result = result.replace(/dddd/i, _ambient.datepicker.vars.weekdays.list[date.getDay()]);
        if (/ddd/i.test(result)) result = result.replace(/ddd/i, _ambient.datepicker.vars.weekdays.abbr[date.getDay()]);
        if (/dd/i.test(result)) result = result.replace(/dd/i, (date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()));
        if (/d/i.test(result)) result = result.replace(/d/i, date.getDate());
        if (/yyyy/i.test(result)) result = result.replace(/yyyy/i, date.getFullYear());
        if (/yy/i.test(result)) result = result.replace(/yy/i, (date.getYear() > 99 ? (date.getYear() < 110 ? "0" + (date.getYear() - 100) : date.getYear() - 100) : date.getYear()));
    } else result = "";
    return result;
};

_ambient.datepicker.functions.datePickerInit = function () {
    if (_ambient && _ambient.htmlObserver) {
        _ambient.htmlObserver.addFunction(_ambient.datepicker.functions.initialize, true);
    } else {
        _ambient.datepicker.functions.initialize();
    }
}

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    _ambient.datepicker.functions.datePickerInit();
} else {
    document.addEventListener("DOMContentLoaded", _ambient.datepicker.functions.datePickerInit);
}
