// ======Ambient Toasts======

if (_ambient.toasts == undefined) _ambient.toasts = {};
if (_ambient.toasts.functions == undefined) _ambient.toasts.functions = {};

// Sets initial toast items, close buttons, and global close delay
_ambient.toasts.items = document.querySelectorAll('[ambient-toast-item]');
_ambient.toasts.items.buttons = document.querySelectorAll('[ambient-toast-item-close]');
_ambient.toasts.closeDelay = undefined;

// Function to dismiss toast when close button is clicked
_ambient.toasts.functions.closeClick = function (e) {
    e.preventDefault();
    if (_ambient.core.functions.findClosestParent(e.target, 'li', [], ['ambient-toast-item'])) _ambient.toasts.functions.removeVisibility(_ambient.core.functions.findClosestParent(e.target, 'li', [], ['ambient-toast-item']));
}

// Function to add the 'toast-visible' class to every toast as each arrives and is added to the DOM
_ambient.toasts.functions.addVisibility = function (elem) {
    elem.classList.add('toast-visible');
}

// Function to remove the 'toast-visible' class whenever a toast is dismissed
_ambient.toasts.functions.removeVisibility = function (elem) {
    elem.classList.remove('toast-visible');
    setTimeout(function () {
        elem.dispatchEvent(new CustomEvent('AmbientToastDismissed', { bubbles: true, detail: { index: elem.getAttribute('ambient-toast-item') } }));
        if (elem && elem.parentNode) {
            elem.parentNode.removeChild(elem);
        }
    }, 501);
}

// Initializer function for toasts
_ambient.toasts.functions.init = function () {
    Object.keys(_ambient.toasts.items.buttons).forEach(function (idx) {
        _ambient.toasts.items.buttons[idx].removeEventListener('click', _ambient.toasts.functions.closeClick);
    })

    if (document.querySelector('[ambient-toast-container-close-delay]')) _ambient.toasts.closeDelay = parseFloat(document.querySelector('[ambient-toast-container-close-delay]').getAttribute('ambient-toast-container-close-delay')) * 1000;
    _ambient.toasts.items = document.querySelectorAll('[ambient-toast-item]');
    _ambient.toasts.items.buttons = document.querySelectorAll('[ambient-toast-item-close]');

    Object.keys(_ambient.toasts.items.buttons).forEach(function (idx) {
        _ambient.toasts.items.buttons[idx].addEventListener('click', _ambient.toasts.functions.closeClick);
    })

    Object.keys(_ambient.toasts.items).forEach(function (idx) {
        var item = _ambient.toasts.items[idx];
        var closeDelay;
        setTimeout(function () {
            if (item && item.classList && !_ambient.core.functions.includes(item.classList, 'toast-visible')) {
                _ambient.toasts.functions.addVisibility(item);
            }
        }, 500);
        if (item && item.attributes && item.attributes['ambient-toast-item-close-delay']) closeDelay = parseFloat(item.attributes['ambient-toast-item-close-delay'].value) * 1000;
        if (closeDelay == undefined) closeDelay = _ambient.toasts.closeDelay;
        if (closeDelay !== 0 && closeDelay !== undefined) {
            setTimeout(function () {
                if (item && item.classList && _ambient.core.functions.includes(item.classList, 'toast-visible')) {
                    _ambient.toasts.functions.removeVisibility(item);
                }
            }, 500 + closeDelay)
        }
    })
}

// Adds initializer function to HTML observer
_ambient.htmlObserver.addFunction(_ambient.toasts.functions.init);
