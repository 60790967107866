// ======Ambient Tab Navigation======

if (_ambient.tab == undefined) _ambient.tab = {};
if (_ambient.tab.functions == undefined) _ambient.tab.functions = {};
if (_ambient.tab.button == undefined) _ambient.tab.button = {};

_ambient.tab.functions.switchTab = function (e) {
    e.preventDefault();
    var clickedButton = _ambient.core.functions.findClosestParent(e.target, 'button', [], [])
    var clickedTab = _ambient.core.functions.findClosestParent(e.target, 'li', [], [])
    var tabParent = _ambient.core.functions.findClosestParent(e.target, 'div', [], ['ambient-tab-navigation'])
    var tabList = tabParent.querySelectorAll('li[role="presentation"]');
    var buttonList = tabParent.querySelectorAll('button[role="tab"]');
    var contentList = document.querySelector('[ambient-tab-navigation-content="' + tabParent.getAttribute('ambient-tab-navigation') + '"]') ? document.querySelector('[ambient-tab-navigation-content="' + tabParent.getAttribute('ambient-tab-navigation') + '"]').querySelectorAll('section[role="tabpanel"') : tabParent.querySelectorAll('section[role="tabpanel"]');

    Object.keys(tabList).forEach(function (idx) {
        if (tabList[idx] === clickedTab) {
            tabList[idx].classList.add('selected');
        } else {
            tabList[idx].classList.remove('selected');
        }
    })

    Object.keys(buttonList).forEach(function (idx) {
        if (buttonList[idx] === clickedButton) {
            buttonList[idx].setAttribute('aria-selected', 'true');
        } else {
            buttonList[idx].removeAttribute('aria-selected');
        }
    })

    Object.keys(contentList).forEach(function (idx) {
        const tabButtonId = clickedButton.getAttribute('tab-button-id');

        if (contentList[idx].getAttribute('aria-labelledby') === tabButtonId) {
            contentList[idx].removeAttribute('hidden');
        } else {
            contentList[idx].setAttribute('hidden', '');
        }
    })

    tabParent.dispatchEvent(new CustomEvent('AmbientTabClicked', { bubbles: true, detail: { elem: clickedButton } }));
}

_ambient.tab.functions.init = function () {

    Object.keys(_ambient.tab.button).forEach(function (idx) {
        _ambient.tab.button[idx].removeEventListener('click', _ambient.tab.functions.switchTab);
    })

    _ambient.tab.button = document.querySelectorAll('[ambient-tab-navigation] ul li button');

    Object.keys(_ambient.tab.button).forEach(function (idx) {
        _ambient.tab.button[idx].addEventListener('click', _ambient.tab.functions.switchTab);
    })


}
_ambient.htmlObserver.addFunction(_ambient.tab.functions.init);
