"use strict";

if (_ambient == undefined) var _ambient = {};
if (_ambient.htmlObserver == undefined) _ambient.htmlObserver = {};

if (_ambient.core == undefined) _ambient.core = {};
if (_ambient.core.functions == undefined) _ambient.core.functions = {};

_ambient.core.functions.loadScript = function (url, callback) {
    // Adding the script tag to the head as suggested before
    var head = document.head;
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onreadystatechange = callback;
    script.onload = callback;

    // Fire the loading
    head.appendChild(script);
}

_ambient.core.functions.findClosestParent = function (elem, tagName, classList, attributeList) {
    var containsTagName = true;
    var containsClasses = true;
    var containsAttributes = true;

    if (elem) {
        if (tagName && elem.tagName && elem.tagName.toLowerCase() !== tagName.toLowerCase()) {
            containsTagName = false;
        }

        Object.keys(classList).forEach(function (idx) {
            if (elem.classList && !elem.classList.contains(classList[idx])) {
                containsClasses = false;
            }
        });

        Object.keys(attributeList).forEach(function (idx) {
            if (elem.attributes && elem.getAttribute(attributeList[idx]) === null) {
                containsAttributes = false;
            }
        });

        // do not return the top document
        if (containsTagName && containsClasses && containsAttributes && elem !== document) {
            return elem;
        } else {
            return _ambient.core.functions.findClosestParent(elem.parentNode, tagName, classList, attributeList);
        }
    } else {
        return null;
    }
}

_ambient.core.functions.getPosition = function (element) {
    var xPosition = 0;
    var yPosition = 0;

    while (element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
}

_ambient.core.functions.checkElementsAreSiblings = function (elm1, elm2) {
    var children = new Array();
    Object.keys(elm1.parentNode.children).forEach(function (idx) {
        children.push(elm1.parentNode.children[idx]);
    })
    return elm1 !== elm2 && children.some(function (child) { return child === elm2 });
}

_ambient.core.functions.includes = function (array, value) {
    var rtn = false;
    Object.keys(array).forEach(function (idx) {
        if (array[idx].toLowerCase() == value.toLowerCase()) rtn = true;
    });
    return rtn;
}

_ambient.core.functions.isVisible = function (elem) {
    return !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
}; // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js

_ambient.core.functions.clearTime = function (date) {
    date.setHours(0, 0, 0, 0);
    return date;
};

_ambient.core.functions.hideIfClickOutside = function (element, callback) {
    // Create event to close the calendar if the user clicks away.
    const outsideClickListener = function (event) {
        const parent = element.parentNode;
        if (!parent.contains(event.target)) {
            if (_ambient.core.functions.isVisible(parent)) {
                element.style.display = 'none';
                removeClickListener();
                if (callback) callback();
            }
        }
    };
    const removeClickListener = function () {
        document.removeEventListener('click', outsideClickListener)
    };
    document.addEventListener('click', outsideClickListener);
};

_ambient.core.functions.focusLock = function (first, last) { // Focus Lock on KeyDown
    const focusLockEvent = function (event, target, shiftTarget) {
        if (!event) event = window.event;
        var keyCode = event.keyCode || event.which, focusRedirected = false;
        if (keyCode === 9) {
            if (event.shiftKey && shiftTarget) {
                shiftTarget.focus();
                focusRedirected = true;
            } else if (target) {
                target.focus();
                focusRedirected = true;
            }
            if (focusRedirected) {
                event.preventDefault();
                return false;
            }
        }
        return true;
    };
    first.addEventListener('keydown', function (event) {
        focusLockEvent(event, null, last)
    });
    last.addEventListener('keydown', function (event) {
        focusLockEvent(event, first)
    });
};

if (_ambient.htmlObserver.HtmlObserverTarget == undefined) _ambient.htmlObserver.HtmlObserverTarget = document.getElementsByTagName('html')[0];

if (_ambient.htmlObserver.HtmlObserverConfig == undefined) _ambient.htmlObserver.HtmlObserverConfig = { attributes: true, childList: true, subtree: true };

if (_ambient.htmlObserver.functions == undefined) _ambient.htmlObserver.functions = [];

if (_ambient.htmlObserver.addFunction == undefined) _ambient.htmlObserver.addFunction = function (func, executeFunction) {
    var foundFunction = false;

    Object.keys(_ambient.htmlObserver.functions).every(function (val, idx) {
        if (_ambient.htmlObserver.functions[idx] == func) {
            foundFunction = true;
        }
        return !foundFunction;
    });

    if (!foundFunction) _ambient.htmlObserver.functions.push(func);

    if (executeFunction && typeof executeFunction === 'boolean') func();

    _ambient.htmlObserver.setObserver();
}

if (_ambient.htmlObserver.removeFunction == undefined) _ambient.htmlObserver.removeFunction = function (func) {
    Object.keys(_ambient.htmlObserver.functions).forEach(function (idx) {
        if (_ambient.htmlObserver.functions[idx] == func) {
            _ambient.htmlObserver.functions.splice(idx, 1);
        }
    });
    _ambient.htmlObserver.setObserver();
}

if (_ambient.htmlObserver.HtmlObserverFunction == undefined) _ambient.htmlObserver.HtmlObserverFunction = function (mutationsList, observer) {
    Object.keys(_ambient.htmlObserver.functions).forEach(function (idx) {
        if (typeof _ambient.htmlObserver.functions[idx] === 'function') _ambient.htmlObserver.functions[idx]();
    });

}

if (_ambient.htmlObserver.setObserver == undefined) _ambient.htmlObserver.setObserver = function () {
    if (_ambient.htmlObserver.mutationObserver == undefined) _ambient.htmlObserver.mutationObserver = new MutationObserver(_ambient.htmlObserver.HtmlObserverFunction);

    _ambient.htmlObserver.mutationObserver.disconnect();
    _ambient.htmlObserver.mutationObserver.observe(_ambient.htmlObserver.HtmlObserverTarget, _ambient.htmlObserver.HtmlObserverConfig);

}

_ambient.htmlObserver.setObserver();
