// ======Ambient Modal======

if (_ambient.modal == undefined) _ambient.modal = {};
if (_ambient.modal.functions == undefined) _ambient.modal.functions = {};

// Sets initial modal variables
_ambient.modal.openButtons = document.querySelectorAll('[ambient-modal-open-button]');
_ambient.modal.closeButtons = document.querySelectorAll('[ambient-modal-close-button]');

// Modal open function
_ambient.modal.functions.openModal = function (e) {
    var parent = _ambient.core.functions.findClosestParent(e.target, 'button', [], ['ambient-modal-open-button']);
    if (parent) {
        parent.dispatchEvent(new CustomEvent('AmbientModalOpen', { bubbles: true, detail: { ariaModal: true } }));
        var modalName = parent.getAttribute('ambient-modal-open-button');
        if (modalName) {
            var modal = document.querySelector('[ambient-modal-name=' + modalName + ']');
            if (modal) {
                modal.setAttribute('aria-modal', 'true');
            }
        }
    }
}

// Modal close function
_ambient.modal.functions.closeModal = function (e) {
    var parent = _ambient.core.functions.findClosestParent(e.target, 'button', [], ['ambient-modal-close-button']);
    if (parent) {
        parent.dispatchEvent(new CustomEvent('AmbientModalClosed', { bubbles: true, detail: { ariaModal: false } }));
        var modalName = parent.getAttribute('ambient-modal-close-button');
        if (modalName) {
            var modal = document.querySelector('[ambient-modal-name=' + modalName + ']');
            if (modal) {
                modal.setAttribute('aria-modal', 'false');
            }
        }
    }
}

// Initializer function for modal
_ambient.modal.functions.init = function () {
    Object.keys(_ambient.modal.openButtons).forEach(function (idx) {
        _ambient.modal.openButtons[idx].removeEventListener('click', _ambient.modal.functions.openModal);
    })

    Object.keys(_ambient.modal.closeButtons).forEach(function (idx) {
        _ambient.modal.closeButtons[idx].removeEventListener('click', _ambient.modal.functions.closeModal);
    })

    _ambient.modal.openButtons = document.querySelectorAll('[ambient-modal-open-button]');
    _ambient.modal.closeButtons = document.querySelectorAll('[ambient-modal-close-button]');

    Object.keys(_ambient.modal.openButtons).forEach(function (idx) {
        _ambient.modal.openButtons[idx].addEventListener('click', _ambient.modal.functions.openModal);
    })

    Object.keys(_ambient.modal.closeButtons).forEach(function (idx) {
        _ambient.modal.closeButtons[idx].addEventListener('click', _ambient.modal.functions.closeModal);
    })
}

// Adds initializer function to HTML observer
_ambient.htmlObserver.addFunction(_ambient.modal.functions.init);
