// ======Ambient Button Aria-Pressed======

if (_ambient.button == undefined) _ambient.button = {};
if (_ambient.button.AriaPressed == undefined) _ambient.button.AriaPressed = {};

_ambient.button.AriaPressed.Toggle = document.querySelectorAll('button[ambient-aria-pressed]');

_ambient.button.AriaPressed.ListenerFunction = function (e) {
    var pressed = this.getAttribute('aria-pressed') === 'true';
    this.setAttribute('aria-pressed', String(!pressed));
    this.dispatchEvent(new CustomEvent('AmbientButtonAriaPressed', { bubbles: true, detail: { ariaPressed: !pressed } }));
}

_ambient.button.AriaPressed.buttonPressFunction = function () {
    Object.keys(_ambient.button.AriaPressed.Toggle).forEach(function (idx) {
        _ambient.button.AriaPressed.Toggle[idx].removeEventListener('click', _ambient.button.AriaPressed.ListenerFunction);
    })

    _ambient.button.AriaPressed.Toggle = document.querySelectorAll('button[ambient-aria-pressed]');

    Object.keys(_ambient.button.AriaPressed.Toggle).forEach(function (idx) {
        var pressed = _ambient.button.AriaPressed.Toggle[idx].getAttribute('aria-pressed');
        if (pressed === null) {
            _ambient.button.AriaPressed.Toggle[idx].setAttribute('aria-pressed', 'false');
        }
        _ambient.button.AriaPressed.Toggle[idx].addEventListener('click', _ambient.button.AriaPressed.ListenerFunction);
    })
}

_ambient.htmlObserver.addFunction(_ambient.button.AriaPressed.buttonPressFunction);


// ======Ambient Button Aria-Expanded======

if (_ambient.button == undefined) _ambient.button = {};
if (_ambient.button.AriaExpanded == undefined) _ambient.button.AriaExpanded = {};

_ambient.button.AriaExpanded.Toggle = document.querySelectorAll('button[ambient-aria-expanded]');

_ambient.button.AriaExpanded.ListenerFunction = function (e) {
    var expanded = this.getAttribute('aria-expanded') === 'true';
    this.setAttribute('aria-expanded', String(!expanded));
    this.dispatchEvent(new CustomEvent('AmbientButtonAriaExpanded', { bubbles: true, detail: { ariaExpanded: !expanded } }));
}

_ambient.button.AriaExpanded.buttonPressFunction = function () {
    Object.keys(_ambient.button.AriaExpanded.Toggle).forEach(function (idx) {
        _ambient.button.AriaExpanded.Toggle[idx].removeEventListener('click', _ambient.button.AriaExpanded.ListenerFunction);
    })

    _ambient.button.AriaExpanded.Toggle = document.querySelectorAll('button[ambient-aria-expanded]');

    Object.keys(_ambient.button.AriaExpanded.Toggle).forEach(function (idx) {
        var expanded = _ambient.button.AriaExpanded.Toggle[idx].getAttribute('aria-expanded');
        if (expanded === null) {
            _ambient.button.AriaExpanded.Toggle[idx].setAttribute('aria-expanded', 'false');
        }
        _ambient.button.AriaExpanded.Toggle[idx].addEventListener('click', _ambient.button.AriaExpanded.ListenerFunction);
    })
}

_ambient.htmlObserver.addFunction(_ambient.button.AriaExpanded.buttonPressFunction);
