// ======Ambient Sortable Table======

if (_ambient.sortableTable == undefined) _ambient.sortableTable = {};
if (_ambient.sortableTable.functions == undefined) _ambient.sortableTable.functions = {};

// Sets initial table variables
_ambient.sortableTable.headers = document.querySelectorAll('[ambient-table-row-header-sortable]');
_ambient.sortableTable.tables = document.querySelectorAll('[ambient-table]');

// Function to check if keypress is 'Enter' key
_ambient.sortableTable.functions.isEnter = function (e) {
    if (e.key && e.key === 'Enter') {
        _ambient.sortableTable.functions.headerClick(e);
    }
}

// Function to sort table when row header is clicked
_ambient.sortableTable.functions.headerClick = function (e) {
    e.preventDefault();
    var table = _ambient.core.functions.findClosestParent(e.target, 'table', [], ['ambient-table']);
    var header = _ambient.core.functions.findClosestParent(e.target, 'th', [], ['ambient-table-row-header-sortable']);
    if (table) _ambient.sortableTable.functions.sortTable(table, header);
}

_ambient.sortableTable.functions.sortTable = function (table, column) {
    var rows, n, headerIdx, switching, i, x, y, xNum, yNum, shouldSwitch, dir;
    switching = true;
    // Set iteration variable based on if table is expandable or not
    n = table.getAttribute('ambient-table-expandable') !== null ? 2 : 1;
    // Set the sorting direction and sort icons based on which header was clicked
    var headers = new Array();
    Object.keys(table.tHead.rows[0].children).forEach(function (idx) {
        headers.push(table.tHead.rows[0].children[idx]);
    })
    Object.keys(headers).forEach(function (idx) {
        if (headers[idx] !== column) {
            headers[idx].removeAttribute('aria-sort');
        }
        if (headers[idx] === column) {
            headerIdx = idx;
        }
    })
    if (column.getAttribute('aria-sort') === 'ascending') {
        column.setAttribute('aria-sort', 'descending');
    } else {
        column.setAttribute('aria-sort', 'ascending');
    }
    dir = column.getAttribute('aria-sort');
    // Make a loop that will continue until no switching has been done
    while (switching) {
        // Start by saying: no switching is done
        switching = false;
        rows = table.rows;
        // Loop through all table rows (except the first, which contains table headers)
        for (i = 1; i < (rows.length - n); i += n) {
            // Start by saying there should be no switching
            shouldSwitch = false;
            // Get the two elements to compare, current row and next row
            x = rows[i].getElementsByTagName("TD")[headerIdx].innerText;
            y = rows[i + n].getElementsByTagName("TD")[headerIdx].innerText;
            xNum = /^\d*\.?\d+$/.test(x.replace(/[^a-zA-Z0-9.//-]/g, '')) ? x.replace(/[^a-zA-Z0-9.//-]/g, '') : null;
            yNum = /^\d*\.?\d+$/.test(y.replace(/[^a-zA-Z0-9.//-]/g, '')) ? y.replace(/[^a-zA-Z0-9.//-]/g, '') : null;
            // Check if the two rows should switch place, based on the direction
            if (dir == "ascending") {
                // Check if values are numbers
                if (!isNaN(Number.parseFloat(xNum)) && !isNaN(Number.parseFloat(yNum))) {
                    if (Number.parseFloat(xNum) > Number.parseFloat(yNum)) {
                        // If rows should be switched, mark as a switch and break the loop
                        shouldSwitch = true;
                        break;
                    }
                    // Check if values are dates
                } else if (!isNaN(Date.parse(x)) && !isNaN(Date.parse(y))) {
                    if (Date.parse(x) > Date.parse(y)) {
                        // If rows should be switched, mark as a switch and break the loop
                        shouldSwitch = true;
                        break;
                    }
                } else if (x.toLowerCase() > y.toLowerCase()) {
                    // If rows should be switched, mark as a switch and break the loop
                    shouldSwitch = true;
                    break;
                }
            } else if (dir == "descending") {
                // Check if values are numbers
                if (!isNaN(Number.parseFloat(xNum)) && !isNaN(Number.parseFloat(yNum))) {
                    if (Number.parseFloat(xNum) < Number.parseFloat(yNum)) {
                        // If rows should be switched, mark as a switch and break the loop
                        shouldSwitch = true;
                        break;
                    }
                    // Check if values are dates
                } else if (!isNaN(Date.parse(x)) && !isNaN(Date.parse(y))) {
                    if (Date.parse(x) < Date.parse(y)) {
                        // If rows should be switched, mark as a switch and break the loop
                        shouldSwitch = true;
                        break;
                    }
                } else if (x.toLowerCase() < y.toLowerCase()) {
                    // If rows should be switched, mark as a switch and break the loop
                    shouldSwitch = true;
                    break;
                }
            }
        }
        if (shouldSwitch) {
            // If a switch has been marked, make the switch and mark that a switch has been done
            rows[i].parentNode.insertBefore(rows[i + n], rows[i]);
            if (n === 2) {
                rows[i].parentNode.insertBefore(rows[i + 3], rows[i + 1]);
            }
            switching = true;
        }
    }

    column.dispatchEvent(new CustomEvent('AmbientTableSorted', { bubbles: true, detail: { elem: column } }));
}

// Initializer function for sortableTable
_ambient.sortableTable.functions.init = function () {
    Object.keys(_ambient.sortableTable.headers).forEach(function (idx) {
        _ambient.sortableTable.headers[idx].removeEventListener('click', _ambient.sortableTable.functions.headerClick);
        _ambient.sortableTable.headers[idx].removeEventListener('keypress', _ambient.sortableTable.functions.isEnter);
    })

    _ambient.sortableTable.headers = document.querySelectorAll('[ambient-table-row-header-sortable]');
    _ambient.sortableTable.tables = document.querySelectorAll('[ambient-table]');

    Object.keys(_ambient.sortableTable.tables).forEach(function (idx) {
        if (_ambient.sortableTable.tables[idx].rows.length > 2 && !_ambient.sortableTable.tables[idx].eventDispatched) {
            _ambient.sortableTable.tables[idx].eventDispatched = true;
            _ambient.sortableTable.tables[idx].dispatchEvent(new CustomEvent('AmbientTableDataPopulated' + _ambient.sortableTable.tables[idx].getAttribute('ambient-table'), { bubbles: true, detail: _ambient.sortableTable.tables[idx] }));
        }
    })

    Object.keys(_ambient.sortableTable.headers).forEach(function (idx) {
        // _ambient.sortableTable.headers[idx].setAttribute(tabindex, 0);
        _ambient.sortableTable.headers[idx].addEventListener('click', _ambient.sortableTable.functions.headerClick);
        _ambient.sortableTable.headers[idx].addEventListener('keypress', _ambient.sortableTable.functions.isEnter);
    })
}

// Adds initializer function to HTML observer
_ambient.htmlObserver.addFunction(_ambient.sortableTable.functions.init);
