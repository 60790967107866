// ======Ambient Expandable Table======

if (_ambient.expandableTable == undefined) _ambient.expandableTable = {};
if (_ambient.expandableTable.functions == undefined) _ambient.expandableTable.functions = {};

// Sets initial table variables
_ambient.expandableTable.rows = document.querySelectorAll('[ambient-table-row-expandable]');
_ambient.expandableTable.headers = document.querySelectorAll('[ambient-table-row-header]');

// Function to check if keypress is 'Enter' key
_ambient.expandableTable.functions.isEnter = function (e) {
    if (e.key && e.key === 'Enter') {
        _ambient.expandableTable.functions.rowClick(e);
    }
}

// Function to open/close expanded row when row is clicked
_ambient.expandableTable.functions.rowClick = function (e) {
    if (_ambient.core.functions.findClosestParent(e.target, 'tr', [], ['ambient-table-row-expandable'])) _ambient.expandableTable.functions.toggleExpandedRow(_ambient.core.functions.findClosestParent(e.target, 'tr', [], ['ambient-table-row-expandable']));
}

// Function to toggle the 'show-expanded-row' class on an expanded row when its corresponding table row is clicked and close any other expanded row
_ambient.expandableTable.functions.toggleExpandedRow = function (elem) {
    if (elem) {
        var currentExpandedRows = document.querySelectorAll('[ambient-table-row-expandable][aria-expanded="true"]');
        var closedRow;

        if (currentExpandedRows.length > 0) {
            Object.keys(currentExpandedRows).forEach(function (idx) {
                if (_ambient.core.functions.checkElementsAreSiblings(elem, currentExpandedRows[idx]) || currentExpandedRows[idx] === elem) {
                    closedRow = currentExpandedRows[idx];
                    currentExpandedRows[idx].setAttribute('aria-expanded', 'false');
                }
            })
        }

        if (elem !== closedRow) {
            elem.setAttribute('aria-expanded', 'true');
            elem.dispatchEvent(new CustomEvent('AmbientTableRowExpanded', { bubbles: true, detail: { elem: elem, data: elem.getAttribute('ambient-table-row-data') } }));
        }
    }
}

// Initializer function for expandableTable
_ambient.expandableTable.functions.init = function () {
    Object.keys(_ambient.expandableTable.rows).forEach(function (idx) {
        _ambient.expandableTable.rows[idx].removeEventListener('click', _ambient.expandableTable.functions.rowClick);
        _ambient.expandableTable.rows[idx].removeEventListener('keypress', _ambient.expandableTable.functions.isEnter);
    })

    _ambient.expandableTable.rows = document.querySelectorAll('[ambient-table-row-expandable]');

    Object.keys(_ambient.expandableTable.rows).forEach(function (idx) {
        _ambient.expandableTable.rows[idx].addEventListener('click', _ambient.expandableTable.functions.rowClick);
        _ambient.expandableTable.rows[idx].addEventListener('keypress', _ambient.expandableTable.functions.isEnter);
    })
}

// Adds initializer function to HTML observer
_ambient.htmlObserver.addFunction(_ambient.expandableTable.functions.init);
